
import {Component, Ref, Vue} from "vue-property-decorator";
import {ExternalProjectProcessMonitoringCreateOrUpdateDto, AttachmentHostType} from "@/api/appService";
import api from "@/api";
import {ElForm} from "element-ui/types/form";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import ProvinceCityArea from "@/components/ProvinceCityArea/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";

@Component({
  name: "ExternalProjectProcessMonitoringEdit",
  components: {
    ProjectSelect,
    ProvinceCityArea,
    MultipleUploadFile
  }
})
export default class ExternalProjectProcessMonitoringEdit extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId!: number;
  projectId!: number;
  defaultData: ExternalProjectProcessMonitoringCreateOrUpdateDto = {
    projectId: undefined,
    startDate: undefined,
    endDate: undefined,
    provinceId: 0,
    provinceName: undefined,
    cityId: 0,
    cityName: undefined,
    areaId: 0,
    areaName: undefined,
    townId: 0,
    townName: undefined,
    place: undefined,
    monitoringType: undefined,
    title: undefined,
    monitoringProcess: undefined,
    observe: undefined,
    feedback: undefined,
    savedAttachments: undefined,
    id: 0
  };
  projectType = "";
  projectList = [];
  // 选择后合作方机构id
  partnerOrgIds = [];
  form: ExternalProjectProcessMonitoringCreateOrUpdateDto = {
    ...this.defaultData
  };

  get hostTypeFile() {
    return AttachmentHostType.External_Project_Process_Monitoring;
  }

  created() {
    this.dataId = Number(this.$route.params.id ?? 0);
    this.projectId = Number(this.$route.params.projectId);
    if (this.dataId > 0) {
      this.fetchDetail();
    } else if (this.dataId == 0 && this.projectId > 0) {
      this.form.projectId = this.projectId;
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.externalProjectProcessMonitoringService
      .get({id: this.dataId})
      .then(res => {
        this.form = {...res};
        this.form.projectId = res!.project!.id;
      });
  }

  cancel() {
    this.$router.go(-1);
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dataId) {
          await api.externalProjectProcessMonitoringService.update({
            body: {...this.form}
          }).then(() => {
            this.$message.success("更新成功");
            this.$router.go(-1);
          });
        } else {
          await api.externalProjectProcessMonitoringService.create({
            body: {...this.form}
          }).then(() => {
            this.$message.success("新建成功");
            this.$router.go(-1);
          });
        }
      } else {
        this.$message({
          type: "error",
          message: "请检查表单"
        });
      }
    });
  }

  linkRule = {
    projectId: [
      {
        required: true,
        message: "请输入项目名称",
        trigger: "blur"
      }
    ],
    startDate: [
      {
        required: true,
        message: "请输入开始时间",
        trigger: "change"
      }
    ],
    endDate: [
      {
        required: true,
        message: "请输入结束时间",
        trigger: "change"
      }
    ],
    townId: [
      {
        required: true,

        message: "请输入省市区",
        trigger: "blur"
      }
    ]
  };
}
